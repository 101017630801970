.pie {
  /*
    --pct: percentage of circle to fill, 0-100
    --s:0 for [0-50]
    --s:1 for [50-100]
  */
  --v:calc( ((18/5) * var(--pct) - 90)*1deg);

  border-radius:50%;
  background:
    linear-gradient(var(--v), var(--bg) 50%,transparent 0) 0 /calc((1 - var(--s))*100%),
    linear-gradient(var(--v), transparent 50%,var(--fg) 0) 0 /calc(var(--s)*100%),
    linear-gradient(to right, var(--bg) 50%,var(--fg) 0);
}